import React, { Component } from 'react';
// import {Container} from "semantic-ui-react";
// import Container from "react-bootstrap/Container";
// import {Container, Row, Col} from 'react-bootstrap'
import "./style.css"

export default class Jenae extends Component {
    
    render(){
        return(
            <div id="jenae" >
           <p> Jenae Studer-Hart</p>
            </div>
        )}}
                