import React from "react";
import "./style.css";

// import { Divider } from "semantic-ui-react";

function ProfilePicture(){
 return(
    
        
     <img
     className="Picture"
     src={require("../../assets/images/n2tsadvuTKGxEwyC+IA5DQ_thumb_12dcf.jpg")} alt= "jenae"/>  


)};


export default ProfilePicture;